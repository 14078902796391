/* eslint-disable arrow-body-style */
/* This is the Root component mainly initializes Redux and React Router. */
import eurekaMgrs from '@eureka/ui-managers';
import '@eureka/ui-managers/src/styles/layout.css';
import '@ui5/webcomponents-icons/dist/Assets-static';
import { ThemeProvider, MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/features/InputSuggestions.js';
import eureka from 'eureka';
import React, { useEffect, useRef, useState } from 'react';
import { Router, Route, Switch, match } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import { renderRouteConfigV3, listenToEventBus, mergeSettings } from './App.helper';
import history from './common/history';
import routeConfig from './common/routeConfig';
import store from './common/store';
import { getURLParam, TestingLocales, setDocumentLang } from './common/Utils';
import { MicroFrontend } from './features/common';
import { useAddonMicroFrontend } from './features/common/useAddonMicroFrontend';
import { getRandom } from './features/common/Utils';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import {
  AppState,
  SetAppState,
  MFE,
  SetMFE,
  ConfigJson,
  UserInfo,
  UserRef,
  User,
  CSRFTokenInfo,
  FetchFunctions,
  Settings,
  SettingsRef,
  isPromiseFulfilledResult,
  isPromiseRejectedResult,
} from './types';

const { MessageToast } = eureka.controls;
const { initI18n, setLanguage, getUi5Language } = eureka.I18nProvider;
const { Spinner } = eureka.components;
const { addConfig, getConfig, setCsrfToken, updateFeatureToggle } = eurekaMgrs.ConfigManager;
const configManagerSetLanguage = eurekaMgrs.ConfigManager.setLanguage;
const configManagerGetLanguage = eurekaMgrs.ConfigManager.getLanguage;
const { getDefaultThemeId, getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

type Props = FetchFunctions;
type ConfigJsonRes = AxiosResponse<ConfigJson>;
type UserInfoRes = AxiosResponse<UserInfo>;
type CSRFTokenInfoRes = AxiosResponse<CSRFTokenInfo>;
type FetchParams = Promise<ConfigJson> | Promise<UserInfo> | Promise<CSRFTokenInfo>;
type FetchRes = ConfigJsonRes | UserInfoRes | CSRFTokenInfoRes;

let config: ConfigJson;
let lng = 'en-US';
let themeId = getDefaultThemeId();
const testingLng = getURLParam(window.location.search, 'sap-ui-language');
const testingThemeId = getURLParam(window.location.search, 'sap-ui-theme');

const getDataFromResults = ({ results, index, defValue = {} }) => {
  return Array.isArray(results) && results.length > index && results[index]?.data
    ? results[index]?.data
    : defValue;
};

const onFetchConfigSuccess = ({
  manifest,
  // state,
  setState,
  setMicroFrontends,
  setAddonMicroFrontends,
}: {
  manifest: ConfigJson;
  // state: AppState;
  setState: SetAppState;
  setMicroFrontends: SetMFE;
  setAddonMicroFrontends: SetMFE;
}) => {
  const shell = eureka.I18nProvider.getLocation(manifest, manifest['shell-ui']);
  const microFrontends: MFE[] = [];
  const addonMicroFrontends: MFE[] = [];
  manifest.components.forEach((component) => {
    const host = eureka.I18nProvider.getLocation(manifest, component);
    const mfe = {
      name: component.config.app,
      host,
      routers: component.config.routers,
    };
    if (component.config.addon) {
      addonMicroFrontends.push(mfe);
    } else {
      microFrontends.push(mfe);
    }
  });
  config = manifest;
  setState((prevState) => ({
    ...prevState,
    config,
  }));
  setMicroFrontends(microFrontends);
  setAddonMicroFrontends(addonMicroFrontends);
  // add app config into config manager
  addConfig('appConfig', config);
  // init i18n
  // i18next configuration: https://www.i18next.com/overview/configuration-options
  initI18n(
    {
      shell,
    },
    {
      debug: process.env.NODE_ENV === 'production',
      lowerCaseLng: false,
      fallbackLng: 'en-US',
      fallbackNS: 'shell',
      whitelist: false,
      lng, // en-US en-US-sappsd
      load: 'currentOnly',
      defaultNS: 'shell',
      ns: 'shell',
      preload: [lng], // en-US en-US-sappsd
      react: {
        useSuspense: false,
        wait: false,
      },
    },
  );
  // Handle error page
  if (window.location.pathname.startsWith('/error')) {
    setState((prevState) => ({
      ...prevState,
      initializing: false,
    }));
  }
};

const onFetchConfigFailed = ({ error }) => {
  console.error(error);
};

const onFetchAuthSuccess = ({ auth, user }: { auth: UserInfoRes; user: UserRef }) => {
  window.hasLoggedin = true;
  if (auth?.data) {
    addConfig('user', auth?.data);
  }
  user.current = auth?.data;
};

export const onFetchAuthFailed = ({
  error,
  // state,
  setState,
}: {
  error: any;
  // state: AppState;
  setState: SetAppState;
}) => {
  if (window.location.href.indexOf('/login') < 0 && error.request.status === 401) {
    window.location.href = '/login?application=rgp';
  } else if (window.location.href.indexOf('/login') < 0 && error.request.status !== 401) {
    window.hasLoggedin = false;
    setState((prevState) => ({
      ...prevState,
      authUserError: error,
    }));
  } else {
    window.hasLoggedin = false;
    console.log(`Auth user error: ${error}`);
    setState((prevState) => ({
      ...prevState,
      initializing: false,
    }));
  }
};

const onFetchCsrfSuccess = ({ csrf }: { csrf: CSRFTokenInfoRes }) => {
  setCsrfToken(csrf?.data?.token);
};

const onFetchCsrfFailed = ({
  error,
  user,
  // state,
  setState,
}: {
  error: any;
  user: UserRef;
  // state: AppState;
  setState: SetAppState;
}) => {
  console.log(`${error}`);
  // set a fake csrf token
  setCsrfToken('fakecsrftoken');
  setState((prevState) => ({
    ...prevState,
    settings: {},
    user: user.current,
    fetchConfigError: false,
  }));
};

const onFetchSettingsSuccess = ({
  results,
  rawSettings,
  // state,
  setState,
  user,
}: {
  results: [any, any];
  rawSettings: SettingsRef;
  // state: AppState;
  setState: SetAppState;
  user: UserRef;
}) => {
  const userProfile = getDataFromResults({ results, index: 0 });
  // If profileTimeZone is null, use UTC-08:00 Pacific Time (US & Canada) -- America/Los_Angeles as default time zone
  userProfile.timeZone = userProfile?.profileTimeZone || 'America/Los_Angeles';
  rawSettings.current = {
    ...rawSettings.current,
    basicSetup: getDataFromResults({ results, index: 0 }),
    userProfile,
    companyProfile: getDataFromResults({ results, index: 1 }),
  };
  const settings = mergeSettings(rawSettings.current);
  // TODO: after basicSetup, userProfile, companyProfile initialized, then change index from 0 to 3
  const currentUser = getDataFromResults({ results, index: 0 });
  const currentUserPermissions = getDataFromResults({ results, index: 1 });
  addConfig(
    'CurrentUserPermissions',
    (currentUserPermissions?.roles || []).map((p) => p.toUpperCase()),
  );
  addConfig('CurrentUser', currentUser);
  addConfig('ThemeSetting', currentUser?.themeId);
  themeId = currentUser?.themeId || getDefaultThemeId();
  if (rawSettings.current.userProfile && rawSettings.current.userProfile.language) {
    lng = rawSettings.current.userProfile.language;
  } else if (rawSettings.current.basicSetup && rawSettings.current.basicSetup.language) {
    lng = rawSettings.current.basicSetup.language;
  }
  user.current.databaseUserId = rawSettings.current.basicSetup?.id;
  setState((prevState) => ({
    ...prevState,
    settings,
    ...(rawSettings.current.userProfile ? { user: rawSettings.current.userProfile } : null),
  }));
};

export const onFetchSettingsFinally = ({
  rawSettings,
  // state,
  setState,
}: {
  rawSettings: SettingsRef;
  // state: AppState;
  setState: SetAppState;
}) => {
  if (testingLng) {
    lng = TestingLocales[testingLng] ? TestingLocales[testingLng] : testingLng;
  }
  if (testingThemeId) {
    themeId = testingThemeId;
  }
  setThemeId(themeId);
  setTheme(getThemeId());
  setLanguage(lng);
  configManagerSetLanguage(lng);
  setUi5Language(getUi5Language(lng));
  setDocumentLang(document, lng);
  // set initialization done
  setState((prevState) => ({
    ...prevState,
    initializing: false,
  }));
};

const processSettledResult = (
  result: PromiseSettledResult<FetchRes>,
  successCb: (any) => void,
  failedCb: (any) => void,
) => {
  if (isPromiseFulfilledResult(result)) {
    successCb && successCb(result.value);
  } else if (isPromiseRejectedResult(result)) {
    failedCb && failedCb(result.reason);
    throw result.reason;
  }
};

export const loader = () => <div>Loading...</div>;

const renderInitializing = () => {
  return (
    <div
      className="app-loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner cssClass="page-load-spinner" />
    </div>
  );
};

export const renderError = (msg: string) => (
  <MessageStrip
    style={{ marginTop: '10px', marginRight: '10px' }}
    design={MessageStripDesign.Negative}
    icon="error"
    hideIcon={false}
    hideCloseButton
  >
    {msg}
  </MessageStrip>
);

export const MicroFrontendWrapper = ({
  history,
  match,
  host,
  name,
  settings,
  user,
}: {
  history: History;
  match: match;
  host: string;
  name: string;
  settings: Settings;
  user: UserRef;
}) => {
  if (!settings) {
    console.error('Settings for microfrontends is empty, which is not allowed');
    return null;
  }
  return (
    <MicroFrontend
      history={history}
      match={match}
      host={host}
      name={name}
      config={config}
      settings={settings}
      user={user.current}
      // eventBus={eventBus}
    />
  );
};

export const renderMicroFrontendRoutes = ({
  mfdRouters,
  history,
  settings,
  user,
}: {
  mfdRouters: MFE[];
  history: History;
  settings: Settings;
  user: UserRef;
}) => {
  const routes: React.ReactElement[] = [];
  mfdRouters.forEach((app) => {
    app.routers.forEach((route) => {
      routes.push(
        <Route
          key={route + getRandom()}
          exact
          path={route}
          component={(props) => (
            <MicroFrontendWrapper
              {...props}
              host={app.host}
              name={app.name}
              history={history}
              settings={settings}
              user={user}
            />
          )}
        />,
      );
    });
  });
  return routes;
};

export const renderMfes = ({
  state,
  user,
  microFrontends,
}: {
  state: AppState;
  user: UserRef;
  microFrontends: MFE[];
}) => {
  console.log('router', routeConfig);
  const containerRoutes = renderRouteConfigV3(routeConfig, '/', config, state.settings, user);
  const microFrontendRoutes = renderMicroFrontendRoutes({
    mfdRouters: microFrontends,
    history,
    settings: state.settings,
    user,
  });
  return (
    <ThemeProvider>
      <Provider store={store}>
        <Router history={history}>
          {/* <WebAssistant lang={configManagerGetLanguage() || 'en-US'} /> */}
          <Switch>
            {microFrontendRoutes as any}
            {containerRoutes}
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

async function onApplicationReady({
  fetchConfig,
  fetchAuth,
  fetchCsrf,
  fetchUserCreate,
  fetchSettings,
  rawSettings,
  setState,
  setMicroFrontends,
  user,
  setAddonMicroFrontends,
}) {
  const [configRes, authRes, csrfRes] = await Promise.allSettled<FetchRes>([
    fetchConfig(),
    fetchAuth(),
    fetchCsrf(),
  ]);
  console.log('configRes', configRes, authRes, csrfRes);
  try {
    processSettledResult(
      configRes,
      (value) =>
        onFetchConfigSuccess({
          manifest: value?.data,
          setState,
          setMicroFrontends,
          setAddonMicroFrontends,
        }),
      (error) => onFetchConfigFailed({ error }),
    );
    processSettledResult(
      authRes,
      (value) => onFetchAuthSuccess({ auth: value, user }),
      (error) => onFetchAuthFailed({ error, setState }),
    );
    processSettledResult(
      csrfRes,
      (value) => onFetchCsrfSuccess({ csrf: value }),
      (error) => onFetchCsrfFailed({ error, user, setState }),
    );
  } catch (error) {
    console.error(error.message);
  }

  if (
    isPromiseFulfilledResult(configRes) &&
    isPromiseFulfilledResult(authRes) &&
    isPromiseFulfilledResult(csrfRes)
  ) {
    try {
      const [userCreateRes, settingsRes] = await Promise.all([fetchUserCreate(), fetchSettings()]);
      const userInfo = {
        ...(isPromiseFulfilledResult(authRes) ? authRes.value?.data : null),
        ...userCreateRes?.data,
      };
      addConfig('user', userInfo);
      user.current = getConfig('user');
      onFetchSettingsSuccess({
        results: settingsRes,
        setState,
        rawSettings,
        user,
      });
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        settings: {},
        user: user.current,
        fetchConfigError: false,
      }));
    }
  }

  onFetchSettingsFinally({ rawSettings, setState });
}

const App: React.FC<Props> = ({
  fetchConfig,
  fetchAuth,
  fetchCsrf,
  fetchUserCreate,
  fetchSettings,
}) => {
  const [state, setState] = useState<AppState>({
    initializing: true,
    fetchConfigError: false,
    authUserError: false,
    config: {},
    settings: { basicSetup: {}, userProfile: {}, companyProfile: {} },
    user: {},
  });
  const [microFrontends, setMicroFrontends] = useState<MFE[] | []>([]);
  const [addonMicroFrontends, setAddonMicroFrontends] = useState<MFE[] | []>([]);
  const user = useRef<UserInfo | Record<string, unknown>>({});
  const rawSettings = useRef<Settings>({ basicSetup: {}, userProfile: {}, companyProfile: {} });
  const { mountAddonMfes, unmountAddonMfes } = useAddonMicroFrontend(
    addonMicroFrontends,
    state.config,
    state.user,
    state.settings,
  );

  useEffect(() => {
    addConfig('application', 'rgp');
    onApplicationReady({
      fetchAuth,
      fetchConfig,
      fetchCsrf,
      fetchUserCreate,
      fetchSettings,
      rawSettings,
      user,
      setState,
      setMicroFrontends,
      setAddonMicroFrontends,
    });
    // return () => {};
  }, [fetchAuth, fetchConfig, fetchCsrf, fetchUserCreate, fetchSettings]);

  useEffect(() => {
    console.log('rawSeetings', history);
    listenToEventBus({ rawSettings, state, setState, history });
    console.log('State Updated', state);
  }, [state]);

  useEffect(() => {
    if (state.initializing) return;
    mountAddonMfes();
    return () => {
      unmountAddonMfes();
    };
  }, [state.initializing, mountAddonMfes, unmountAddonMfes]);

  if (state.fetchConfigError) {
    return renderError('Failed to load config, please try again.');
  }

  if (state.authUserError) {
    return renderError('Failed to get user information, please refresh page.');
  }

  if (state.initializing) {
    return renderInitializing();
  }

  return renderMfes({ state, user, microFrontends });
};

export default App;
