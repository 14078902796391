/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import HeartBeatWebSocket from './HeartBeatWebSocket';
import { COMMON_GET_NOTIFICATIONS_SUCCESS, COMMON_GET_NOTIFICATIONS_FAILED } from './constants';
import { AxiosInstance } from 'axios';
import { Notification } from 'src/types';

const { eventBus } = eurekaMgrs;
const { axiosCreate } = eureka.HttpWrapper;
const { getConfig } = eurekaMgrs.ConfigManager;

const injectInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const { tenantId, id } = getConfig('user') || {};
    config.headers['X-Tenant-ID'] = tenantId;
    config.headers['X-User-ID'] = id;
    return config;
  });
  return axiosInstance;
};

const notificationAxios = injectInterceptor(
  axiosCreate({
    baseURL: '/api/rgp-notification-push/v1/messages',
  }).instance,
);
let __dispatch;
let __websocket;
let isMessageEnabled = false;

function fetchNotifications(args = {}, search = '', sortBy = '', pageSize = 10, pageCount = 1) {
  const promise = new Promise((resolve, reject) => {
    const doRequest = notificationAxios({
      url: '',
      method: 'get',
      withCredentials: true,
    });
    doRequest.then(
      (res) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_SUCCESS,
          data: (res && res.data) || [],
        });
        resolve(res);
      },
      (err) => {
        __dispatch({
          type: COMMON_GET_NOTIFICATIONS_FAILED,
          data: err,
        });
        reject(err);
      },
    );
  });
  return promise;
}

function onMessage(evt: { data: any }) {
  if (__dispatch && !evt.data.startsWith('HeartBeat')) {
    fetchNotifications();
  }
  // refresh deduction table and status
  if (evt?.data) {
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'Deduction' &&
      evt.data.changeAction === 'AutoFill'
    ) {
      eventBus.emit('DeductionAutoFilled', '', evt.data);
    }
    // tell visit-execution pload is finished
    if (
      evt.data.businessObject &&
      evt.data.businessObject === 'VISIT_PHOTO' &&
      evt.data.changeAction === 'ADD_ATTACHMENT'
    ) {
      eventBus.emit('VisitPhotoUploaded', '', evt.data);
    }
  }
}

export function refreshNewMessages() {
  return setInterval(() => {
    if (isMessageEnabled) {
      fetchNotifications();
    }
  }, 60 * 1000);
}

export function closeWebsocket() {
  return (disptach) => {
    __websocket && __websocket._ws && __websocket._ws.close();
  };
}

export function startWebsocket() {
  return (dispatch) => {
    if (__dispatch) {
      return;
    }
    __dispatch = dispatch;
    if (!__websocket) {
      const protocol = window.location.protocol.startsWith('https') ? 'wss' : 'ws';
      const url = `${protocol}://${window.location.host}/api/rgp-notification-push/`;
      __websocket = new HeartBeatWebSocket(url, onMessage).start();
    }
    fetchNotifications().then(
      () => {
        isMessageEnabled = true;
      },
      () => {
        isMessageEnabled = false;
        console.log('Message channel is supported, so will not start web socket');
      },
    );

    // listen close websocket of eventbus
    eventBus.on('close-websocket', (evtBody) => {
      console.log(evtBody);
      console.log('web socket close of eventbus');
      closeWebsocket();
    });
  };
}

export function dismissNotification(notification: Notification) {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/${notification.id}`,
        method: 'POST',
        withCredentials: true,
      });
      doRequest
        .then(() => fetchNotifications())
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err);
        });
    });
}

export function dismissAllNotifications() {
  return (disptach) =>
    new Promise((resolve, reject) => {
      const doRequest = notificationAxios({
        url: `/ack-all`,
        method: 'post',
        withCredentials: true,
      });
      doRequest
        .then(() => fetchNotifications())
        .then((res) => resolve(res))
        .catch((err) => {
          reject(err);
        });
    });
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.data,
      };
    case COMMON_GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
}
