import React, { useEffect } from 'react';
import { Select, Option, Label } from '@ui5/webcomponents-react';
import { SegmentedButton, ToggleButton } from '@ui5/webcomponents-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { fetchTimeZone } from '../redux/actions';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
} from './options';
import { useTranslation } from './eureka';
import { Ui5CustomEvent } from '@ui5/webcomponents-react';
import { UserProfileState } from 'src/types';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    data: UserProfileState;
    languages: languages[];
    onDataPropChange: (value: string, propName: string) => void;
  };

interface languages {
  language: string;
  languageCode: string;
  displayLanguage: {
    nativeLanguage: string;
    englishLanguage: string;
  };
  displayLanguageCode: string;
}

const LanguageAndRegion: React.FC<Props> = ({
  data,
  languages,
  actions,
  common,
  onDataPropChange,
}) => {
  const { t } = useTranslation('shell');
  const { fetchTimeZone } = actions;
  const { timezone } = common;

  useEffect(() => {
    if (!timezone) {
      fetchTimeZone();
    }
  }, []);

  return (
    <div style={{ padding: '10px 22px' }}>
      <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          onChange={(
            evt: Ui5CustomEvent<
              HTMLSelectElement,
              {
                selectedOption: React.ReactNode;
              }
            >,
          ) => {
            const lang = (evt.detail.selectedOption as HTMLSelectElement).dataset.id;
            /* istanbul ignore next */
            lang && onDataPropChange(lang, 'language');
          }}
          id="language"
          data-testid="select-language"
          style={{ width: '100%' }}
        >
          {languages.map((language: languages) => {
            return (
              <Option
                key={language.languageCode}
                data-id={language.languageCode}
                value={language.languageCode}
                selected={language.languageCode === data.language}
              >
                {`${language.displayLanguage.nativeLanguage} (
                  ${language.language}
                )`}
              </Option>
            );
          })}
        </Select>
      </div>
      {/* <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          value={data.dateFormat}
          onChange={(
            evt: Ui5CustomEvent<
              HTMLSelectElement,
              {
                selectedOption: React.ReactNode;
              }
            >,
          ) => {
            const lang = (evt.detail.selectedOption as HTMLSelectElement).dataset.id;
            lang && onDataPropChange(lang, 'dateFormat');
          }}
          id="dateFormat"
          style={{ width: '100%' }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div> */}
      {/* <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}:
        </Label>
        <SegmentedButton
          onSelectionChange={(
            evt: Ui5CustomEvent<
              HTMLButtonElement,
              {
                selectedItem: React.ReactNode;
              }
            >,
          ) => {
            onDataPropChange((evt.detail.selectedItem as HTMLButtonElement).id, 'timeFormat');
          }}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <ToggleButton
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                type: 'but',
                desc: '',
              })}
            </ToggleButton>
          ))}
        </SegmentedButton>
      </div> */}
      {/* <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_TimeZone', 'Time Zone', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          onChange={(
            evt: Ui5CustomEvent<
              HTMLSelectElement,
              {
                selectedOption: React.ReactNode;
              }
            >,
          ) => {
            const lang = (evt.detail.selectedOption as HTMLSelectElement).dataset.id;
            lang && onDataPropChange(lang, 'profileTimeZone');
          }}
          id="timeZone"
          style={{ width: '220px' }}
        >
          {timezone?.map((timeZone, index) => {
            return (
              <Option
                key={timeZone.timezone}
                data-id={timeZone.timezone}
                value={timeZone.timezone}
                selected={timeZone.timezone === data.profileTimeZone}
              >
                {timeZone.displayName}
              </Option>
            );
          })}
        </Select>
      </div> */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, fetchTimeZone }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageAndRegion);
