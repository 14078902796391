import { useCallback } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import { ConfigJson, MFE, Settings } from 'src/types';
import history from 'src/common/history';

const { ProxyHelper } = eurekaMgrs.ProxyManager;
const { fetchApplicationManifestAssets, renderApplication, unmountApplication, fetchManifest } =
  ProxyHelper;

export const useAddonMicroFrontend = (
  mfes: MFE[],
  config: ConfigJson | Record<string, unknown>,
  user: any,
  settings: Settings,
) => {
  const renderAddonMicroFrontend = useCallback(
    async (mfe: MFE) => {
      try {
        const { name, host } = mfe;
        const manifest = await fetchManifest({ host, name });
        await fetchApplicationManifestAssets(
          manifest,
          manifest?.files['main.js']?.startsWith('http') ? '' : host,
          name,
          config,
        );
        const container = document.createElement('div');
        container.id = `${name.toLowerCase()}-container`;
        container.innerHTML = `<div id="${name.toLowerCase()}-content}"></div>`;
        document.body.appendChild(container);
        renderApplication(
          name,
          history,
          {
            history,
            host,
            user,
            config,
            settings,
          },
          container,
        );
      } catch (e) {
        console.error(e);
      }
    },
    [config, settings, user],
  );

  const mountAddonMfes = useCallback(() => {
    mfes.forEach((mfe) => renderAddonMicroFrontend(mfe));
  }, [mfes, renderAddonMicroFrontend]);

  const unmountAddonMfes = useCallback(() => {
    mfes.forEach((mfe) => unmountApplication(mfe.name));
  }, [mfes]);

  return {
    mountAddonMfes,
    unmountAddonMfes,
  };
};
