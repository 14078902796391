/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState, memo } from 'react';
import { useLocation } from 'react-router';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { MessageStrip, MessageStripDesign } from '@ui5/webcomponents-react';
import DefaultLayout from './DefaultLayout';
import MashupLayout from './MashupLayout';
import { ThemingParameters } from '@ui5/webcomponents-react-base';
import { MicroFrontendProps } from 'src/types';

type Props = MicroFrontendProps & {
  getManifest?: () => Promise<{ data: unknown }>;
  getAppPreference?: () => Promise<{ data: unknown }>;
};
type renderMicroFrontend = MicroFrontendProps & {
  container: ContainerRef;
  fetchManifestError: boolean;
  userPreferencePromise: Promise<{
    data: unknown;
  }>;
};
type renderApp = MicroFrontendProps & {
  container: ContainerRef;
};
type ContainerRef = React.MutableRefObject<HTMLDivElement | null>;

const { UserPreferenceManager, eventBus } = eurekaMgrs;
const { ProxyHelper } = eurekaMgrs.ProxyManager;
const { fetchApplicationManifestAssets, renderApplication, unmountApplication, fetchManifest } =
  ProxyHelper;
const { getCurrentLanguage } = eureka.I18nProvider;
const { Spinner } = eureka.components;

export const hasLoggedin = () => {
  return !!window.hasLoggedin;
};

export const defaultGetAppPreference = (name: string) => {
  return Promise.resolve({
    data: {},
  });
};

export const renderApp = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
}: renderApp) => {
  renderApplication(
    name,
    history,
    {
      history,
      match,
      host,
      user,
      eventBus,
      config,
      settings,
    },
    container,
    config,
  );
  /* istanbul ignore next */
};

export const renderMicroFrontend = ({
  history,
  match,
  host,
  config,
  name,
  user,
  settings,
  container,
  fetchManifestError,
  userPreferencePromise,
}: renderMicroFrontend) => {
  if (fetchManifestError) {
    return;
  }
  userPreferencePromise
    .then((res) => {
      UserPreferenceManager.setAppSetting(name, res.data);
    })
    .catch((err) => {
      /* istanbul ignore next */
      console.log('Load user preference error:', err);
    })
    .finally(() => {
      renderApp({ history, match, host, config, name, user, settings, container });
    });
};

export const renderError = () => {
  return (
    <MessageStrip
      icon="message-error"
      style={{ margin: '10px' }}
      hideCloseButton={false}
      design={MessageStripDesign.Negative}
    >
      Failed to load asset manifest, please try again.
    </MessageStrip>
  );
};

const InnerContainer = memo(
  ({ name, containerRef }: { name: string; containerRef: ContainerRef }) => {
    return (
      <div
        id="microfrontend-viewport"
        data-testid={`${name}-microfrontend-render-container`}
        style={{ height: '100%', background: ThemingParameters.sapBackgroundColor }}
      >
        <div id={`${name.toLowerCase()}-container`} style={{ height: '100%' }}>
          <div
            id={`${name.toLowerCase()}-content`}
            className="microfrontent-content"
            ref={containerRef}
            style={{ height: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

/* istanbul ignore next */
const MicroFrontend: React.FC<Props> = ({
  name,
  host,
  match,
  history,
  config,
  settings,
  user,
  getManifest,
  getAppPreference = defaultGetAppPreference,
}) => {
  const location = useLocation();
  const containerRef = useRef(null);
  const [fetchManifestError, setFetchManifestError] = useState(false);
  const userPreferencePromise = (getAppPreference || defaultGetAppPreference)(name);
  const query = new URLSearchParams(location.search);
  const isEmbed = query.get('embedded') === 'true';

  useEffect(() => {
    const containerCurrent = containerRef.current;
    if (name !== 'mfe-login' && !hasLoggedin()) {
      history.push('/login?application=rgp');
    } else if (containerCurrent) {
      fetchManifest({ host, name, getManifest }).then((manifest) => {
        // manifest host is equal to `http://localhost:2120`
        fetchApplicationManifestAssets(
          manifest,
          manifest?.files['main.js']?.startsWith('http') ? '' : host,
          name,
          config,
        ).then(
          () => {
            renderMicroFrontend({
              history,
              match,
              host,
              config,
              name,
              user,
              settings,
              container: containerCurrent,
              fetchManifestError,
              userPreferencePromise,
            });
          },
          (err) => {
            console.log('Load main asset error:', err);
            setFetchManifestError(true);
          },
        );
      });

    }
    return () => {
      if (fetchManifestError) {
        return;
      }
      unmountApplication(name);
    };
  }, []);
  if (fetchManifestError) {
    return (
      <DefaultLayout
        match={match}
        history={history}
        config={config}
        user={user}
        settings={settings}
        children={renderError()}
      />
    );
  }

  const MicroFrontendInner = InnerContainer;

  if (name.toLowerCase() === 'mfe-login') {
    return (
      <div style={{ height: '100%' }}>
        <MicroFrontendInner name={name} containerRef={containerRef} />
      </div>
    );
  }

  if (isEmbed) {
    return (
      <MashupLayout
        match={match}
        history={history}
        config={config}
        settings={settings}
        user={user}
        children={<MicroFrontendInner name={name} containerRef={containerRef} />}
      />
    );
  }

  return (
    <DefaultLayout
      match={match}
      history={history}
      config={config}
      settings={settings}
      user={user}
      children={<MicroFrontendInner name={name} containerRef={containerRef} />}
    />
  );
};

export default MicroFrontend;
