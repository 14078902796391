/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import eurekaMgrs from '@eureka/ui-managers';

const { getCsrfToken, getConfig } = eurekaMgrs.ConfigManager;

export default class HeartBeatWebSocket {
  _ws!: WebSocket;
  _url!: string;
  _interval!: NodeJS.Timer;
  _messageHandler: (evt) => void;
  _onOpenHandler?: () => void;
  constructor(url: string, messageHandler: (evt) => void, onOpenHandler?: () => void) {
    this._url = url;
    this._messageHandler = messageHandler;
    this._onOpenHandler = onOpenHandler;
    this._onWebSocketOpen = this._onWebSocketOpen.bind(this);
    this._onWebSocketClose = this._onWebSocketClose.bind(this);
    this._onWebSocketMessage = this._onWebSocketMessage.bind(this);
    this._startHeartBeat = this._startHeartBeat.bind(this);
    this._isOpen = this._isOpen.bind(this);
  }

  start() {
    if (!this._ws) {
      this.forceInitWebSocket();
    }
    return this;
  }

  forceInitWebSocket() {
    console.log(`[${new Date().toISOString()}] Force init Websocket connection`);
    if (this._ws) {
      this.close();
      this._ws.onopen = null;
      this._ws.onclose = null;
      this._ws.onmessage = null;
      this._ws.onerror = null;
    }
    const { tenantId, id } = getConfig('user') || {};
    // pass tenant id and user id to wss directly, no sidecar
    // this._ws = new WebSocket(this._url, [`${getCsrfToken()}_${tenantId}_${id}`]);
    this._ws = new WebSocket(this._url, [getCsrfToken()]);
    this._ws.onopen = this._onWebSocketOpen;
    this._ws.onclose = this._onWebSocketClose;
    this._ws.onmessage = this._onWebSocketMessage;
    this._ws.onerror = this._onWebSocketError;
  }

  _isOpen(ws) {
    return ws.readyState === ws.OPEN;
  }

  _onWebSocketOpen() {
    console.log(`Web Socket Connection to ${this._url} started.`, this._ws.readyState);
    if (this._isOpen(this._ws)) {
      if (this._onOpenHandler) {
        this._onOpenHandler();
      }
      this._interval = setInterval(this._startHeartBeat, 30000);
    }
  }

  _onWebSocketClose() {
    console.log(`Web Socket Connection to ${this._url} closed.`);
    this.forceInitWebSocket && this.forceInitWebSocket();
    console.log(`[${new Date().toISOString()}] Web Socket reconnected because of onclose`);
  }

  _onWebSocketError(error) {
    console.log(error);
    this.forceInitWebSocket && this.forceInitWebSocket();
    console.log(`[${new Date().toISOString()}] Web Socket reconnected because of onerror`);
  }

  _onWebSocketMessage(evt: { data: any }) {
    const msg = evt.data;
    if (msg.startsWith('HeartBeat')) {
      // We don't process heartbeat echo message
      return;
    }
    try {
      this._messageHandler && this._messageHandler(evt);
    } catch (e) {
      console.log(e);
    }
  }

  _startHeartBeat() {
    if (!this._isOpen(this._ws)) {
      console.log(`[${new Date().toISOString()}] Websocket connection is closed`);
      this.forceInitWebSocket();
    } else {
      try {
        this._ws.send(`HeartBeat-${new Date().toISOString()}`);
      } catch (err) {
        console.error(
          `[${new Date().toISOString()}] Websocket send data, error: ${err.toString()}`,
        );
      }
    }
  }

  close() {
    clearInterval(this._interval);
    this._ws && this._ws.close();
  }
}
