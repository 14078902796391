/* eslint-disable operator-linebreak */
import React, { useEffect, useRef } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import { SideNavigation } from '@ui5/webcomponents-react';
import { Ui5DomRef } from '@ui5/webcomponents-react';
import { Ui5CustomEvent } from '@ui5/webcomponents-react';
import UI5Element from '@ui5/webcomponents-base/dist/UI5Element.js';
const { getDefaultThemeId, getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

export interface SideNavigationObserverProps {
  className: string;
  style: any;
  collapsed: boolean;
  selectedId: string;
  noIcons: boolean;
  onSelectionChange: (event: Ui5CustomEvent<HTMLElement, { item: React.ReactNode }>) => void;
}

let EventMap = {};

let TreeNodeTag = 'ui5-tree';
let ListNodeTag = 'ui5-list';
let LiTreeTag = 'ui5-li-tree';
let IconTag = 'ui5-icon';
if (process.env.NODE_ENV !== 'test') {
  TreeNodeTag = `ui5-tree-${process.env.APP_NAME}`;
  ListNodeTag = `ui5-list-${process.env.APP_NAME}`;
  LiTreeTag = `ui5-li-tree-${process.env.APP_NAME}`;
  IconTag = `ui5-icon-${process.env.APP_NAME}`;
}

const getTreeRoot = (currentDom: Ui5DomRef) =>
  currentDom?.shadowRoot?.querySelector(
    `nav.ui5-sn-root ul[role="tree"].ui5-sn-list.ui5-sn-flexible`,
  );

const attachEventsForAll = (
  currentDom: Ui5DomRef,
  clickHandler: (evt: React.MouseEvent) => void,
) => {
  const treeRoot = getTreeRoot(currentDom);
  const firstLevelItems = treeRoot?.querySelectorAll(`li.ui5-sn-list-li`);
  if (firstLevelItems?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < firstLevelItems.length; i++) {
      const item = firstLevelItems[i] as UI5Element;
      if (item) {
        if (item.className.indexOf('ui5-sn-list-li') >= 0) {
          // eslint-disable-next-line no-underscore-dangle
          EventMap[item.__id as any] = {
            item,
            clickHandler,
          };
          (item as any).onclick = clickHandler;
        }
      }
    }
  }
};

const detachEventsForAll = () => {
  Object.keys(EventMap).forEach((key: string) => {
    const entry = EventMap[key];
    if (entry.item) {
      entry.item.onclick = null;
    }
  });
  EventMap = {};
};

const clickHandler = (evt: React.MouseEvent) => {
  const target: any = evt.target as UI5Element;
  const treeItem = target.parentNode;
  if (treeItem.querySelector('ui5-icon-rgp-shell.ui5-sn-item-toggle-icon')) {
    treeItem.querySelector('ui5-icon-rgp-shell.ui5-sn-item-toggle-icon').fireEvent('click');
  }
};

const SideNavigationObserver: React.FC<SideNavigationObserverProps> = React.forwardRef(
  (props, ref) => {
    const item = useRef<Ui5DomRef>(null);
    useEffect(() => {
      const currentDom = item.current;
      const timer = setTimeout(() => {
        if (currentDom?.shadowRoot) {
          const treeRoot = currentDom.shadowRoot.querySelector(`.ui5-sn-root`);
          if (treeRoot) {
            if (treeRoot.className.indexOf('ui5-sn-collapsed') < 0) {
              attachEventsForAll(currentDom, clickHandler);
            }
            // clear background
            const treeItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell');
            treeItems.forEach((item) => {
              if (item?.shadowRoot?.querySelector('li')?.style) {
                const li = item.shadowRoot.querySelector('li') as any;
                if (li.style.background) {
                  li.style.background = 'none';
                }
              }
            });
            const allItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell[selected]');
            const isMorning = getThemeId() === 'sap_horizon';
            allItems.forEach((item) => {
              if (item?.shadowRoot?.querySelector('li')?.style && isMorning) {
                const li = item.shadowRoot.querySelector('li') as any;
                li.style.background = '#D1EFFF';
              }
            });
          }
        }
      }, 0);
      return () => {
        detachEventsForAll();
        clearTimeout(timer);
      };
    });

    return <SideNavigation ref={item} {...props} />;
  },
);

export default SideNavigationObserver;
