export const NOTIFICATION_MESSAGE_TYPE = {
  INTEGRATION: {
    name: 'INTEGRATION',
    promotionChangeActions: ['P_ACTION_DELETE', 'P_ACTION_RELEASE', 'P_ACTION_SUBMIT'],
  },
  ACCOUNT_PLAN: {
    name: 'ACCOUNT_PLAN',
    accountPlanChangeActions: ['UPLOAD_ACCOUNT_PLAN_BASELINE'],
  }
};

export const NOTIFICATION_ACTION_TYPE = {
  NAVIGATION: 'NAVIGATION',
};

export const NOTIFICATION_BO_TYPE = {
  ResponsibilityArea: 'ResponsibilityArea',
};
