import axios from 'axios';
import eureka from 'eureka';
import { ConfigJson, UserInfo, CSRFTokenInfo } from 'src/types';

const { axiosCreate } = eureka.HttpWrapper;
const axiosWrapper = axiosCreate();
const axiosWithCSRF = axiosWrapper.instance;

export const fetchFunctions = {
  fetchConfig() {
    return axios.get<ConfigJson>('/config.json');
  },
  fetchAuth() {
    return axios.get<UserInfo>('/auth/user');
  },
  fetchCsrf() {
    return axios.request<CSRFTokenInfo>({
      headers: {
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      url: `/auth/csrf-token`,
      method: 'POST',
      withCredentials: true,
    });
  },
  fetchUserCreate() {
    return axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/create');
  },
  fetchSettings() {
    return Promise.all([
      axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/me'),
      axiosWithCSRF.get('/api/user-configuration-plus/business/v1/users/roles'),
    ]);
  },
};

export default () => {
  return fetchFunctions;
};
